<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      regexForVariable: new RegExp(/{{((?:.(?!{{))+?)(?:\|variable)?}}/g)
    };
  },
  computed: {
    ...mapGetters("variables", {
      variableIds: "variableIds",
      xSipVariableNames: "xSipVariableNames"
    })
  },
  methods: {
    invalidVariableIdsPresentInAValue(value, runCheck = true) {
      if (!value || !runCheck) {
        return [];
      }
      let val = _.cloneDeep(value);
      if (_.isObject(val)) {
        val = val.toString();
      }
      let variableIdsRegexIterator = val.matchAll(this.regexForVariable);
      let variableIds = _.map(
        Array.from(variableIdsRegexIterator),
        match => match[1]
      );
      return _.filter(
        variableIds,
        variableId =>
          (_.isEmpty(this.xSipVariableNames) &&
            !_.includes(this.variableIds, variableId)) ||
          (!_.isEmpty(this.xSipVariableNames) &&
            !/XSIP_[\S]+/.test(variableId.toString()) &&
            !_.includes(this.variableIds, variableId))
      );
    },
    isValueSingleVariable(value) {
      return /^{{-?\d+}}$/g.test(value);
    }
  }
};
</script>
